import * as React from 'react';
import { StatefulPopover, PLACEMENT, TRIGGER_TYPE } from 'baseui/popover';
import { useStyletron } from 'baseui';
import { connect } from 'react-redux';
import IconBlack from '../../images/icons8-help-50.png';
import IconWhite from '../../images/icons8-help-50-white.png';
import { Icon } from 'react-icons-kit';
import { help } from 'react-icons-kit/ionicons/help';
/**
 *
 * @param {*} displayType how to show info => click || hover
 */
const HelpQuestionMark = ({
  text,
  triggerType = TRIGGER_TYPE.hover,
  embedCss = {},
  themeMode,
  button,
  className,
  cardCoverPreview,
  placement = PLACEMENT.right,
  backgroundColor,
  color,
  style,
  iconHeight
}) => {
  const [css, theme] = useStyletron();
  return (
    <>
      <StatefulPopover
        content={() => (
          <div
            className={css({
              padding: '16px',
              maxWidth: '400px',
              fontSize: '14px',
            })}
          >
            {text}
          </div>
        )}
        triggerType={triggerType}
        accessibilityType={'tooltip'}
        showArrow
        placement={placement}
        overrides={{
          Body: {
            style: {
              zIndex: 100,
            }
          }
        }}
      >
        {button ? (
          <div className='question-mark-help-icon-div'>
            <Icon
              className='question-mark-help-icon'
              icon={help}
              className={css({
                color: color || 'white',
                background: backgroundColor ||'rgba(255,255,255,0.3)',
                borderRadius: '50%',
                padding: '2px 7px 6px',
                cursor: 'pointer',
                ...style
              })}
            />
          </div>
        ) : (
          <img
            src={themeMode == 'dark' && !cardCoverPreview ? IconWhite : IconBlack}
            className={css({
              height: iconHeight || '24px',
              marginLeft: '16px',
              marginTop: '4px',
              marginBottom: 0,
              cursor: 'pointer',
              ...embedCss,
            })}
          ></img>
        )}
      </StatefulPopover>
    </>
  );
};

const mapStateToProps = props => {
  let { theme } = props.appBasicControls;
  return {
    themeMode: theme,
  };
};
export default connect(mapStateToProps)(HelpQuestionMark);
