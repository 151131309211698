import * as React from 'react';
import { KIND, SHAPE } from 'baseui/button';
import { useStyletron } from 'baseui';
import HelpQuestionMark from './help/questionMarkHelp';
import Button from './common/button';
import { Plus, Icon } from 'baseui/icon';
import { Input } from 'baseui/input';

export default ({
  buttonText,
  buttonType,
  helpText,
  inputPlaceholder,
  inputValue,
  setInputValue,
  onSave,
  isLoading,
  buttonClickOverride,
  style={}
}) => {
  const [showInput, setShowInput] = React.useState(false);
  const [css, theme] = useStyletron();
  const B = require('baseui/button').Button;

  React.useEffect(() => {
    setShowInput(isLoading);
  }, [isLoading]);

  let backgroundColor = buttonType && ( buttonType === 'light' ? theme.colors.primaryC : 'white') || 'white';
  // let border = buttonType && ( buttonType === 'light' ? `2px solid ${theme.colors.primaryC}` : `0px solid` ) || `0px solid`;
  let plusIconColor = buttonType && ( buttonType === 'light' ? 'white' : '5D64AB' ) || '#5D64AB';
  
  let helpBackgroundColor =  buttonType && ( buttonType === 'light' ? '#4A4CA0' : 'rgba(255,255,255,0.3)' );
  let helpIconColor = 'white';

  const updatedStyle  = {
    
    fontWeight: '700',    
    ...style,
    ':hover': {
      background: 'linear-gradient(180deg, #765FED 0%, #E646A2 100%) !important',
      color: 'white !important',
      ...style[':hover']
    },
    ':hover .start_enhancer': {
      color: `${theme.colors.primaryC} !important`,
      background: 'white !important',
      border: '0px solid',
      ...style[':hover .start_enhancer']
    },
  }
  return (
    <React.Fragment>
      <div
        className={css({
          display: 'flex',
        })}
      >
        {!showInput ? (
          <Button
            border_property={"true"}
            shape={SHAPE.pill}
            buttonType={buttonType}
            onClick={() =>
              buttonClickOverride ? buttonClickOverride() : setShowInput(true)
            }
            text={buttonText}
            style={updatedStyle}
            startEnhancer={() => (
              <Plus
                className='start_enhancer'
                size={16}
                overrides={{
                  Svg: {
                    style: {
                      backgroundColor,
                      color: plusIconColor,
                      borderRadius: '2px',
                    },
                  },
                }}
              />
            )}
            endEnhancer={() => <HelpQuestionMark text={helpText} button backgroundColor={helpBackgroundColor} color={helpIconColor}/>}
          />
        ) : (
          <>
            <Input
              placeholder={inputPlaceholder}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              overrides={{
                Root: {
                  style: ({ $theme, $isFocused }) => ({
                    width: '500px',
                    borderBottomWidth: $isFocused ? '1px' : '0px',
                    borderRightWidth: '0px',
                    borderTopWidth: '0px',
                    borderLeftWidth: '0px',
                    fontSize: '18px',
                    // backgroundColor: $theme.colors.primary50,
                    backgroundColor: $theme.colors.primary50,
                    borderBottom: $isFocused ? '1px solid #5D64AB' : 'none',
                    marginRight: '16px',
                  }),
                },
              }}
              onKeyUp={e => {
                if (e.keyCode == 13) {
                  onSave();
                }
              }}
              disabled={isLoading}
            />
            <Button
              onClick={() => onSave()}
              isLoading={isLoading}
              text="Save"
            />
            <B
              kind={KIND.minimal}
              onClick={() => {
                setShowInput(false);
              }}
              className={css({
                color: theme.colors.primaryA,
                cursor: 'pointer',
              })}
            >
              Cancel
            </B>
          </>
        )}
      </div>
    </React.Fragment>
  );
};
