import * as React from 'react';
import { Button, SHAPE } from 'baseui/button';
import { useStyletron } from 'baseui';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

const StyledButton = styled(Button)`
`;

const CustomButton = props => {
  const [css, theme] = useStyletron();

  let buttonHoverStyle = props && props.style && props.style[':hover'] ? props.style[':hover'] : {};
  let buttonOnHoverStartEnhStyle = props && props.style && props.style[':hover .start_enhancer'] ? props.style[':hover .start_enhancer'] : {};
  let buttonOverrides = {
    BaseButton: {
      style: ({ $theme }) => ({
        backgroundColor: props.backgroundColor || $theme.colors.primaryC,
        color: props.color || 'white',
        // borderRadius: props.shape? undefined : props.border_property ? '5px' : '0',
        fontSize: '12px',
        padding: '10px 14px',
        fontWeight: '700',
        ...props.style || {},
        ':hover': {
          background: 'linear-gradient(180deg, #765FED 0%, #E646A2 100%) !important',
          color: 'white !important',
          ...buttonHoverStyle
        },
        ':hover .start_enhancer': {
          color: `${theme.colors.primaryC} !important`,
          background: 'white !important',
          border: '0px solid',
          ...buttonOnHoverStartEnhStyle
        },
      }),
    },
  }

  const dynamicHover =  props.disableHover ? {
    backgroundColor: props.backgroundColor || theme.colors.primaryC
  } : {
    backgroundColor: '#313131 !important',
    color: 'white',
    svg: {
      color: '#313131 !important',
    },
  };
  
  return(
    <StyledButton
      {...props}
      className={css({
        ':hover': props.disableHover ? {
          backgroundColor: props.backgroundColor || theme.colors.primaryC
        } : {
          backgroundColor: '#313131 !important',
          color: 'white',
          svg: {
            color: '#313131 !important',
          },
        },
      })}
      shape={SHAPE.pill}
      overrides={buttonOverrides}
      isLoading={props.isLoading}
    >
      {props.text}
    </StyledButton>
  );
};


CustomButton.propTypes = {
  style: PropTypes.shape({})
}

CustomButton.defaultProps = {
  style: {}
}

export default CustomButton;